<script>
import leftArrow from './assets/left-arrow.svg'
import rightArrow from './assets/right-arrow.svg'

export default {
	data() {
		return { leftArrow, rightArrow }
	},
	props: {
		data: {
			type: Object,
			default: () => {},
		},
	},
	computed: {
		isMobile() {
			return this.$vuetify.breakpoint.smAndDown
		},
	},
	methods: {
		goPrev() {
			this.$emit('prev')
			this.$refs.title.scrollIntoView({ behavior: 'smooth' })
		},
		goNext() {
			this.$emit('next')
			this.$refs.title.scrollIntoView({ behavior: 'smooth' })
		},
	},
	mounted() {
		document.querySelector('html').style.overflow = 'hidden'
	},
	destroyed() {
		document.querySelector('html').removeAttribute('style')
	},
}
</script>
<template>
	<div class="w100 parent d-flex flex-column align-center coral">
		<div class="d-flex align-center justify-end py-5 px-10 w100 fixed">
			<v-icon size="40" color="black" @click="$emit('close')">mdi-close</v-icon>
		</div>
		<div
			v-if="data.title"
			class="h3 text-center font-weight-medium pt-16 px-10 px-md-5"
			:class="{ h4: isMobile }"
			style="max-width: 1024px"
			ref="title"
		>
			{{ data.title.rendered }}
		</div>
		<Media
			v-if="data.featured_image_url"
			:src="data.featured_image_url"
			width="100%"
			max-height="500px"
			cover
			class="my-10"
		/>
		<Container max-width="1024px" width="100%">
			<div class="t5 d-flex align-center font-weight-bold" v-if="data.date">
				<div class="line mr-2"></div>
				<div>
					{{ data.date }}
				</div>
			</div>
			<div
				class="t1 font-weight-bold my-5 px-3 px-md-10 text-center text-md-start"
				:class="{ 'text-content-mb': isMobile, 'text-content': !isMobile }"
				v-html="data.content.rendered"
			></div>
			<div class="d-flex justify-space-between font-weight-bold my-15" :class="isMobile ? 't3' : 't2'">
				<div v-if="data.prev" class="btn" @click="goPrev">
					<v-icon class="mb-3" v-if="isMobile" large>mdi-chevron-left</v-icon>
					<div class="mb-3">{{ data.prev }}</div>
					<Media :src="leftArrow" alt="prev blog" width="30px" contain v-if="!isMobile" />
				</div>
				<div v-else></div>
				<div v-if="data.next" class="btn text-end" @click="goNext">
					<v-icon class="mb-3" v-if="isMobile" large>mdi-chevron-right</v-icon>
					<div class="mb-3">{{ data.next }}</div>
					<Media :src="rightArrow" alt="next blog" width="30px" contain v-if="!isMobile" />
				</div>
			</div>
		</Container>
	</div>
</template>
<style>
.parent {
	max-height: 100%;
	position: fixed;
	top: 0;
	z-index: 10;
	overflow-y: scroll;
	background: white;
}
.line {
	background: black;
	width: 50px;
	height: 1px;
}
.fixed {
	position: fixed;
	top: 0;
	z-index: 20;
}
.btn {
	max-width: 200px;
	cursor: pointer;
}
.text-content > figure {
	display: flex;
	justify-content: space-between;
}

.text-content-mb .wp-block-image {
	display: block;
	margin-bottom: 30px;
}

.text-content > h1,
h2,
h3,
h4,
h5 {
	margin: 2rem 0 0.5rem 0;
}
</style>
