<script>
const apiUrl = 'https://blog.whiskyboutique.com.ar//wp-json/wp/v2/posts'
export default {
	data: () => ({
		data: [],
		modalActive: false,
		blogData: undefined,
	}),
	async created() {
		this.data = await this.getData()
	},
	methods: {
		async getData() {
			const res = await fetch(apiUrl)
			return await res.json()
		},
		activateModal(item, i) {
			this.blogData = {
				...item,
				index: i,
				prev: i === 0 ? null : this.data[i - 1].title.rendered,
				next: i === this.data.length - 1 ? null : this.data[i + 1].title.rendered,
			}
			this.modalActive = true
		},
		prevBlog() {
			let index = this.blogData.index
			this.activateModal(this.data[index - 1], index - 1)
		},
		nextBlog() {
			let index = this.blogData.index
			this.activateModal(this.data[index + 1], index + 1)
		},
	},
}
</script>

<template>
	<div v-if="data.length" class="coral pb-10">
		<SectionTitle title="novedades - nuestro blog" />
		<Container width="100%" max-width="1100px">
			<v-row class="my-10">
				<v-col cols="12" md="4" v-for="(item, i) of data" :key="i" class="px-15 px-md-10">
					<v-card hover flat tile color="transparent" @click="activateModal(item, i)">
						<Media min-height="400px" :src="item.featured_image_url" width="100%"> </Media>
						<div class="black--text text-uppercase font-weight-bold t4 pt-3 pb-2 border">
							<div>
								{{ item.title.rendered }}
							</div>
						</div>
						<div class="py-2 d-flex justify-end black--text text-uppercase font-weight-bold t4">
							Ver nota
						</div>
					</v-card>
				</v-col>
			</v-row>
			<div class="text-center">
				<SafeLink to="/blog">
					<WButton x-large>Ver mas</WButton>
				</SafeLink>
			</div>
		</Container>
		<BlogModal
			:data="blogData"
			v-if="modalActive"
			@close="modalActive = false"
			@prev="prevBlog"
			@next="nextBlog"
		/>
	</div>
</template>

<style>
.border {
	border-bottom: 1px solid var(--v-gray-base);
}
</style>
