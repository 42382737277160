<script>
export default {
	metaInfo: {
		title: 'Sobre el Whisky',
	},
	pageView: true,
	mounted() {
		this.$prerenderReady()
	},
}
</script>
<template>
	<div>
		<AboutWhisky-A-Header />
		<AboutWhisky-B-Origins />
		<AboutWhisky-C-World />
		<AboutWhisky-D-Blog />
	</div>
</template>
