<script>
export default {
	data() {
		return {
			items: this.$srv('About_Whisky_World', []),
			active: null,
		}
	},
	computed: {
		isMobile() {
			return this.$vuetify.breakpoint.smAndDown
		},
	},
	created() {
		this.active = this.items[0]
	},
	methods: {
		scrollea() {
			const el = document.getElementById('active-image')
			el.scrollIntoView()
		},
	},
}
</script>

<template>
	<div class="relative">
		<Home-BaresDelMundo @click:action-btn="scrollea" />
		<Container :fluid="isMobile" max-width="1024px" width="100%" class="px-3 py-15">
			<Media :src="active.img" width="100%" id="active-image" />
			<div class="mt-15">
				<v-row>
					<v-col
						cols="12"
						md="3"
						class="d-flex flex-column text-uppercase"
						:class="isMobile && 'align-center'"
					>
						<div
							v-for="(item, i) of items"
							:key="i"
							@click="active = item"
							:class="{ 'item-active': active.title === item.title }"
							class="mb-5 cursor"
						>
							<span class="font-weight-bold t1"> {{ item.title }}</span>
							<span class="mx-2">-</span>
							<span class="t5">
								{{ item.location }}
							</span>
						</div>
					</v-col>
					<v-col cols="12" md="9" class="pl-md-15" :class="isMobile && 'align-center'">
						<div class="t1 font-weight-bold px-3">{{ active.text }}</div>
					</v-col>
				</v-row>
			</div>
		</Container>
	</div>
</template>

<style scoped>
.item-active {
	color: var(--v-gold-base);
	border-bottom: 1px solid var(--v-gold-base);
}

.cursor {
	cursor: pointer;
}
</style>
