<script>
export default {
	data() {
		return { items: this.$srv('About_Whisky_Origins', []) }
	},
	computed: {
		isMobile() {
			return this.$vuetify.breakpoint.smAndDown
		},
	},
}
</script>
<template>
	<div class="coral py-15">
		<Container>
			<v-row v-for="(item, index) of items" :key="index" class="px-md-15 mb-15">
				<v-col
					cols="12"
					md="8"
					:order="!item.inverted && !isMobile ? 1 : 3"
					class="px-10 d-flex flex-column justify-space-between"
				>
					<div>
						<div
							class="h5 font-weight-bold text-uppercase"
							:class="isMobile && 'text-center'"
							:style="isMobile && 'font-size: 20px;'"
						>
							{{ item.title }}
						</div>
						<div class="t1 font-weight-bold mt-5" :class="isMobile && 't3 text-center'">
							{{ item.text }}
						</div>
					</div>
					<div
						class="t5 font-weight-bold text-uppercase mt-5 d-flex align-center"
						:class="!item.inverted && 'justify-end'"
					>
						<div class="line flex-grow-1" v-if="item.inverted"></div>
						<span class="mx-3">
							{{ item.description }}
						</span>
						<div class="line flex-grow-1" v-if="!item.inverted"></div>
					</div>
				</v-col>
				<v-col cols="12" md="4" order="2" class="px-10">
					<Media :src="item.img" contain></Media>
				</v-col>
			</v-row>
		</Container>
	</div>
</template>
<style scoped>
.line {
	max-width: 10rem;
	height: 1px;
	background: black;
}
</style>
